import React, {useState, useRef, createRef, useEffect} from 'react'
import styles from './../../styles/modules/testimonials.module.scss'

const Testimonials = ({data}) => {
  const [page, setPage] = useState(0)
  const [width, setWidth] = useState()

  const elementsRef = useRef(data.testimonials.map(() => createRef()))
  useEffect(() => {
    setWidth(document.getElementsByClassName(styles.testimonialContainer)[0].offsetWidth)
    const slideContainer = document.getElementsByClassName(styles.slideContainer)[0]
    slideContainer.style.transform = `translate(-${width * page}px, 0)`
  }, [page])

  return (
    <section className={styles.testimonials}>
      <div className={styles.wrapper}>
        <h2 className="lineTitle">{data.title}</h2>
        <p>{data.description}</p>
        <div className={styles.testimonialContainer}>
          <div className={styles.slideContainer}>
            {
              data.testimonials.map((data, index) => <div ref={elementsRef.current[index]} className={styles.testimonial} key={index} style={{
                width: width + 'px'
                }} >
                  <Testimonial data={data}/> 
                </div>
              )
            }
          </div>
          <div className={styles.pagination}>
            {
              data.testimonials.map(
                (item, index) => <div onClick={() => setPage(index)} className={`${styles.item} ${selectPage(index, page)}`} key={index}></div>
              )
            }
          </div>
        </div>
      </div>
    </section>
  )
}

const Testimonial = ({data}) => (
    <>
      <img src={data.profile.childImageSharp.fluid.src} alt={data.name}/>
      <div className={styles.name}>{data.name}</div>
      <div className={styles.title}>{data.title}</div>
      <div className={styles.content}>
      “{data.content}”
      </div>
    </>
)

const selectPage = (toBeSelected, currentPage) => {
  return toBeSelected === currentPage ? styles.selected : ''
}

export default Testimonials
