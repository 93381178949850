import React from 'react'
import { Link } from 'gatsby'
import homeStyles from './../../styles/modules/homepage.module.scss'

const ThirdsFeature = ({title, linkName, linkHref, children}) => (
  <section className={homeStyles.thirdsFeature}>
    <div className={homeStyles.wrapper}>
      <h2 className="lineTitle">{title}</h2>
      <div className={homeStyles.container}>
        {children}
      </div>
      { linkName && linkHref ? (
          <div className={homeStyles.cta}>
            <Link to={linkHref} className="button">{ linkName }</Link>
          </div>
        ) : ''
      }
    </div>
  </section>
)

export default ThirdsFeature
