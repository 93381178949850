import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout.jsx'
import SEO from '../components/seo.jsx'
import homeStyles from './../styles/modules/homepage.module.scss'

import Description from '../components/homepage/description.jsx'
import ThirdsFeature from '../components/homepage/thirdsFeature.jsx'
import Testimonials from '../components/homepage/testimonials.jsx'
import Clients from '../components/homepage/clients.jsx'
// import ArticleThumb from '../components/common/articleThumb.jsx'

const IndexPage = ({data}) => {
  const projects = data.projects.edges.map(project => project.node.frontmatter)
  // const articles = data.articles.edges.map(project => project.node.frontmatter)
  const home = data.homepage

  return (
    <Layout header="complex">
      <SEO title="Home" kywords={[`web`, `developer`, 'frontend', 'front end', 'front-end', 'ux', 'designer', 'javascript developer', 'react']} />
      <main className={homeStyles.main}>
        <div className="clear"></div>

        <Description description={home.description} skills={home.skills}/>

        { <ThirdsFeature title={home.projects.title} linkName={home.projects.button} linkHref={home.projects.buttonLink}>
          <ListFeaturedProjects projects={projects}/>
        </ThirdsFeature> }

        <Clients/>

        {/* <ThirdsFeature title={home.articles.title} linkName={home.articles.button} linkHref={home.articles.buttonLink}>
          {
            articles.map((article, index) => <ArticleThumb className={homeStyles.item} article={article} key={index}/>)
          }
        </ThirdsFeature> */}

        <Testimonials data={home.testimonials}/>
      </main>

    </Layout>
  )
}

const ListFeaturedProjects = ({projects}) => projects.map( (item, index) =>
  <div className={`${homeStyles.item} ${homeStyles.workItem}`} key={index}>
    <Link to={`/work/${item.slug}`}>
      <img src={item.thumbnail.childImageSharp.fluid.src} alt={item.title} />
    </Link>
  </div>
)

export const staticQuery = graphql`
{
  projects: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(projects)/.*.md$/"}, frontmatter: {featured: {eq: true}}}
		limit: 3
  ) {
    edges {
      node {
        frontmatter {
          title
          slug
          thumbnail {
            childImageSharp{
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
  articles: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(blog)/.*.md$/"}}
  	limit: 3
  ) {
    edges {
      node {
        frontmatter {
          title
          slug
          date
          thumbnail {
            childImageSharp{
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  },
  homepage: indexJson {
    header {
      description
      button {
        name
      }
      link {
        name
      }
    }
    description {
      title
      content
    }
    skills {
      frontend
      backend
      design
    }
    projects {
      title
      button
      buttonLink
    }
    clients {
      title
    }
    articles {
      title
      button
      buttonLink
    }
    testimonials {
      title
      content
      testimonials {
        name
        title
        content
        profile {
          childImageSharp{
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}`

export default IndexPage
