import React from 'react'
import styles from './../../styles/modules/clients.module.scss'

import debenhams from './../../images/clients/debenhams.jpg'
import ovo from './../../images/clients/ovo.jpg'
import belstaff from './../../images/clients/belstaff.jpg'
import evans from './../../images/clients/evans.jpg'
import worcester from './../../images/clients/worcester.jpg'
// import dorothyPerkins from './../../images/clients/dorothy-perkins.jpg'
import biscuiteers from './../../images/clients/biscuiteers.jpg'
import mentionme from './../../images/clients/mentionme.jpg'
import ifootage from './../../images/clients/ifootage.png'
import workvine from './../../images/clients/workvine.jpg'

const Testimonials = () => (
  <section className={styles.clients}>
    <div className={styles.wrapper}>
      <h2 className="lineTitle">I have also helped these awesome companies</h2>
      <div className={styles.container}>
        {
          images.map((item, index) => 
            <img src={item.image} key={index} alt={item.title}/>
          )
        }
      </div>
    </div>
  </section>
)

const images = [
  {
    image: worcester,
    title: 'University of Worcester'
  },
  {
    image: mentionme,
    title: 'MentionME'
  },
  {
    image: ifootage,
    title: 'Ifootage'
  },
  {
    image: debenhams,
    title: 'Debenhams'
  },
  {
    image: ovo,
    title: 'OVO Energy'
  },
  {
    image: belstaff,
    title: 'Belstaff'
  },
  {
    image: evans,
    title: 'Evans'
  },
  {
    image: workvine,
    title: 'Workvine'
  },
  
  // {
  //   image: dorothyPerkins,
  //   title: 'Dorothy Perkins'
  // },
  {
    image: biscuiteers,
    title: 'Biscuiteers'
  },
]

export default Testimonials
