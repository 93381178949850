import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import homeStyles from './../../styles/modules/homepage.module.scss'

const Description = () => {
  return  <StaticQuery query={graphql`
      query {indexJson {
        description {
          title
          content
        }
        skills {
          title
          frontend
          backend
          design
        }
      }}
    `}
    render={data => {
      const { description, skills } = data.indexJson
      return(
        <section className={homeStyles.description}>
          <div className={homeStyles.halfContainer}>
            <h2 className="lineTitle">{description.title}</h2>
            {description.content.map((p, index) => {
              return <p key={index} dangerouslySetInnerHTML={{__html: p}} />
            })}
          </div>
          <div className={homeStyles.halfContainer}>
            <h2 className="lineTitle">{skills.title}</h2>
            <div className={homeStyles.whatIDo}>
              <div className={homeStyles.item}>
                <FrontendIcon/>
                {skills.frontend.map((s, index) => <p key={index}>{s}</p>)}
              </div>
              <div className={homeStyles.item}>
                <BackendIcon/>
                {skills.backend.map((b, index) => <p key={index}>{b}</p>)}
              </div>
              <div className={homeStyles.item}>
                <DesignIcon/>
                {skills.design.map((d, index) => <p key={index}>{d}</p>)}
              </div>
            </div>
          </div>
        </section>
      )
      }
    }
  />
}

const FrontendIcon = () => (
  <svg width="77px" height="77px" viewBox="0 0 77 77" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="0%" y1="0%" x2="80.2154694%" y2="100%" id="linearGradientFrontEnd">
        <stop stopColor="#FF8989" offset="0%"></stop>
        <stop stopColor="#FFDDC5" offset="100%"></stop>
      </linearGradient>
      <path
        d="M12.816,20.072 L6.432,20.072 L5.304,23 L-0.456,23 L6.888,6.2 L12.456,6.2 L19.8,23 L13.944,23 L12.816,20.072 Z M11.256,15.992 L9.624,11.768 L7.992,15.992 L11.256,15.992 Z"
        id="pathFE-2"></path>
    </defs>
    <g transform="translate(-799.000000, -912.000000)">
      <g transform="translate(799.000000, 912.000000)">
        <circle fill="url(#linearGradientFrontEnd)" cx="38.5" cy="38.5" r="38.5"></circle>
        <g transform="translate(27.000000, 27.000000)">
          <g>
            <g fill="#000000">
              <rect id="brand-path-3" x="0" y="0" width="23" height="23" rx="4"></rect>
            </g>
            <g fill="#FFFFFF">
              <rect id="brand-path-3" x="0" y="0" width="23" height="23" rx="4"></rect>
            </g>
          </g>
          <rect fill="#FFE2E0" x="5" y="5" width="13" height="13" rx="4"></rect>
        </g>
        <g transform="translate(36.000000, 18.000000)">
          <g>
            <use fill="#000000" xlinkHref="#pathFE-2"></use>
            <use fill="#E86A63" xlinkHref="#pathFE-2"></use>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const BackendIcon = () => (
  <svg width="77px" height="77px" viewBox="0 0 77 77" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="12.629094%" y1="2.33397313%" x2="117.1836%" y2="130.121595%"
        id="linearGradientBackEnd">
        <stop stopColor="#9CB3FF" offset="0%"></stop>
        <stop stopColor="#B0FFE9" offset="100%"></stop>
      </linearGradient>
      <circle id="pathBE-2" cx="38.5" cy="38.5" r="38.5"></circle>
      <circle id="pathBE-4" cx="38.5" cy="38.5" r="38.5"></circle>
    </defs>
    <g transform="translate(-998.000000, -911.000000)">
      <g id="fe" transform="translate(998.000000, 911.000000)">
        <g fill="url(#linearGradientBackEnd)">
          <circle cx="38.5" cy="38.5" r="38.5"></circle>
        </g>
        <g>
          <mask id="maskBE-3" fill="white">
            <use xlinkHref="#pathBE-2"></use>
          </mask>
          <g mask="url(#maskBE-3)">
            <g transform="translate(26.000000, 29.000000)">
              <g>
                <g id="ui-Rectangle-14">
                  <g fill="#000000">
                    <rect x="0" y="0" width="27" height="19" rx="2"></rect>
                  </g>
                  <g fill="#FFFFFF">
                    <rect x="0" y="0" width="27" height="19" rx="2"></rect>
                  </g>
                </g>
                <path
                  d="M2,0 L25,0 C26.1045695,-2.02906125e-16 27,0.8954305 27,2 L27,5 L0,5 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z"
                   fill="#D6E5FF"></path>
              </g>
            </g>
          </g>
        </g>
        <g >
          <mask id="maskBE-5" fill="white">
            <use xlinkHref="#pathBE-4"></use>
          </mask>
          <g></g>
          <g mask="url(#maskBE-5)">
            <g transform="translate(40.000000, 23.000000)">
              <g>
                <rect fill="#4C82DE" x="0" y="0" width="17" height="20" rx="2"></rect>
                <circle fill="#FFFFFF" cx="8.5" cy="15.5" r="1.5"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const DesignIcon = () => (
  <svg width="77px" height="77px" viewBox="0 0 77 77" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradientUX">
        <stop stopColor="#FFA8E8" offset="0%"></stop>
        <stop stopColor="#FFE6E6" offset="100%"></stop>
      </linearGradient>
    </defs>
      <g transform="translate(-1204.000000, -911.000000)">
        <g id="be" transform="translate(1204.000000, 911.000000)">
          <circle fill="url(#linearGradientUX)" cx="38.5" cy="38.5"
            r="38.5"></circle>
          <g transform="translate(27.000000, 28.000000)">
            <g>
              <g fill="#000000">
                <rect x="0" y="0" width="27" height="19" rx="2"></rect>
              </g>
              <g fill="#FFFFFF">
                <rect x="0" y="0" width="27" height="19" rx="2"></rect>
              </g>
            </g>
            <path
              d="M2,0 L25,0 C26.1045695,-2.02906125e-16 27,0.8954305 27,2 L27,5 L0,5 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z"
               fill="#FFE6F6"></path>
          </g>
          <g strokeLinecap="round" strokeLinejoin="round" transform="translate(42.000000, 27.000000)" stroke="#E778C1" strokeWidth="4">
            <polyline points="0.128498246 0 6 5.93505544 0 12" fill="transparent"></polyline>
            <path d="M7,13 L15,13"></path>
          </g>
        </g>
      </g>
  </svg>
)


export default Description
